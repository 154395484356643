<template>
    <div class="navbar-form navbar-left navbar-search-form search" role="search">
        <div class="input-group" :class="{active: isActive}">
            <span class="input-group-addon">
                <span class="fa fa-spinner fa-spin icon" v-if="loading"></span>

                <template v-else>
                    <span class="fa fa-search icon" v-show="isEmpty"></span>
                    <span class="fa fa-times icon" v-show="isDirty" @click="reset"></span>
                </template>
            </span>

            <input type="text"
                   :placeholder="searchLabel"
                   autocomplete="off"
                   v-model="query"
                   class="form-control"
                   @keydown.down="down"
                   @keydown.up="up"
                   @keydown.enter="hit"
                   @keydown.esc="reset"
                   @blur="reset"
                   @input="update"/>
        </div>

        <!-- the list -->
        <ul v-show="hasItems" class="list-group items">
            <li class="list-group-item" v-for="(item, $index) in items" :class="activeClass($index)" @mousedown="hit" @mousemove="setActive($index)">
                <span class="icon pe-7s-angle-right"></span>
                <span class="icon icon-active pe-7s-angle-right-circle"></span>

                <span v-text="item.name" class="name"></span><br/>

                <small class="text-muted">
                    <span v-text="item.domain"></span>

                    <template v-if="item.clientManager">
                        <span class="fa fa-user fa-fw"></span> <span v-text="item.clientManager.name"></span>
                    </template>

                    <template v-if="item.industry">
                        <span class="fa fa-tag fa-fw"></span> <span v-text="item.industry.title"></span>
                    </template>
                </small>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
    .search {
        position: relative;
        width: 100%;
        margin-left: -18px !important;

        .input-group {
            width: 100%;

            .input-group-addon {
                width: 40px;
            }

            border: 1px solid #eeeeee;
            border-radius: 5px;

            &:hover,
            &:focus,
            &.active {
                border-color: #cbcbcb;
            }
        }

        input[type="text"] {
            width: 100%;
            position: relative;
            background-color: transparent;
        }

        ul.items {
            position: absolute;
            width: calc(100% - 21px);
            z-index: 300;
            border-radius: 0 0 5px 5px;

            li {
                position: relative;
                cursor: pointer;
                font-size: 13px;
                border-color: #cbcbcb;
                border-top-color: #f5f5f5;
                padding: 15px 20px;

                .name {
                    font-size: 14px;
                }

                .icon {
                    position: absolute;
                    right: 15px;
                    top: 17px;
                    font-size: 35px;
                }

                .icon-active {
                    display: none;
                }

                &.active {
                    color: #ff7837;
                    background-color: #fff;
                    border-color: #cbcbcb;
                    border-top-color: #f5f5f5;
                    border-bottom-color: #f5f5f5;

                    .name {
                        font-weight: 500;
                    }

                    .icon-active {
                        display: block;
                    }
                }

                &:first-child {
                    border-top: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    margin-top: -5px;
                    padding-top: 20px;
                }

                &:last-child {
                    border-bottom-color: #cbcbcb;
                }
            }
        }
    }
</style>

<script>
  import { mapActions } from 'vuex'
  import VueTypeahead from 'vue-typeahead'

  export default {
    extends: VueTypeahead,

    computed: {
      searchLabel () {
        return $t('backend.search.placeholder')
      },

      isActive () {
        return this.activeElement === this.el
      },

      data () {
        return {
          includes: [
            'clientManager',
            'industry',
            'integrations'
          ],

          filter_groups: [
            {
              or: true,
              filters: [
                {
                  key: 'domain',
                  value: this.query,
                  operator: 'ct'
                },
                {
                  key: 'name',
                  value: this.query,
                  operator: 'ct'
                },
                {
                  key: 'industry.title',
                  value: this.query,
                  operator: 'ct'
                },
                {
                  key: 'integrations.data',
                  value: this.query,
                  operator: 'eq'
                }
              ]
            }
          ]
        }
      }
    },

    data () {
      return {
        src: 'customers',
        limit: 10,
        minChars: 3,
        selectFirst: true,
        queryParamName: 'q',
        error: false,
        el: null,
        activeElement: null
      }
    },

    created () {
      document.addEventListener('focusin', this.focusChanged)
      document.addEventListener('focusout', this.focusChanged)
    },

    beforeDestroy () {
      document.removeEventListener('focusin', this.focusChanged)
      document.removeEventListener('focusout', this.focusChanged)
    },

    mounted () {
      this.el = this.$el.querySelector('input[type="text"]')
    },

    methods: {
      ...mapActions('auth', {
        refreshToken: 'refreshToken'
      }),

      onHit (item) {
        if (!item) {
          return
        }

        this.$router.push({
          name: 'backend.customers.view',
          params: {
            id: item.id
          }
        })

        this.reset()
      },

      focusChanged (event) {
        if (event.type === 'focusout') {
          this.activeElement = null
          return
        }

        this.activeElement = event.target
      },

      prepareResponseData (data) {
        return data ? data.rows : []
      },

      fetch () {
        const errorObj = {
          data: {
            limit: 1,
            page: 0,
            rows: [],
            total: 0
          }
        }

        if (this.error || !this.$http || !this.src) {
          return errorObj
        }

        const src = this.queryParamName
          ? this.src
          : this.src + this.query

        const params = this.queryParamName
          ? Object.assign({ [this.queryParamName]: this.query }, this.data)
          : this.data

        return this.$http.get(src, { params }).catch(async () => {
          if (!this.error) {
            this.error = true

            await this.refreshToken()

            this.error = false
            this.update()
          }

          return errorObj
        })
      }
    }
  }
</script>
