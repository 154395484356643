<template>
    <div class="integrations-progress-bar">
        <div class="progress" :style="{'height': height}">
            <div class="progress-bar" role="progressbar" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" :style="progressStyleObject"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import * as ServiceService from '@/services/integrations/ServiceService'

    import {mapGetters} from 'vuex'

    export default {
        props: {
            height: String
        },

        data() {
            return {
                listOfServices: [],
            }
        },

        watch: {
            identity() {
                if (!this.identity) {
                    return
                }

                this.loadServices()
            },

            numberOfActiveIntegrations() {
                this.$emit('numberOfActiveIntegrations', this.numberOfActiveIntegrations)
            },

            serviceNames() {
                this.$emit('numberOfServices', this.serviceNames.length)
            },
        },

        mounted() {
            setTimeout(this.loadServices, 500)
        },

        computed: {
          ...mapGetters('identity', {
            isClient: 'isClient',
            isAdmin: 'isAdmin',
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer',
          }),

            progressStyleObject() {
                return {
                    width: this.progress + '%',
                    backgroundColor: $org('colors.standard.secondary.hex')
                }
            },

            serviceNames() {
                return this.listOfServices.map((service) => {
                    return service.name
                })
            },

            numberOfActiveIntegrations() {
                if (!this.customer) {
                    return 0
                }

                const customer = this.customer

                if (!customer.integrations) {
                    return 0
                }

                const integrations = customer.integrations

                let activeIntegrations = []

                integrations.forEach((integration) => {
                    if (!integration.service) {
                        return
                    }

                    const service = integration.service

                    const serviceName = service.name

                    if (!this.serviceNames.includes(serviceName)) {
                        return
                    }

                    // Check for duplicate
                    if (activeIntegrations.includes(serviceName)) {
                        return
                    }

                    activeIntegrations.push(serviceName)
                })

                return activeIntegrations.length
            },

            progress() {
                if (this.listOfServices.length === 0) {
                    return 0
                }

                const percentage = (this.numberOfActiveIntegrations/this.listOfServices.length) * 100

                return percentage
            }
        },

        methods: {
            loadServices() {
                if (!this.identity) {
                    return
                }

                ServiceService.findAll({
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'visibleToUser',
                                    value: '1',
                                    operator: 'eq'
                                }
                            ]
                        }
                    ]
                }, (response) => {
                    const data = response.data

                    this.listOfServices = data.rows
                }, () => {

                })
            },
        },
    }
</script>
