<template>
    <div class="topbar-wrapper">
        <nav class="navbar navbar-default top-bar hidden-xs hidden-sm">
            <div class="container-fluid">
                <div class="collapse navbar-collapse">
                    <div class="fill">
                        <ul class="nav navbar-nav navbar-left" v-if="isClient">
                            <li>
                                <div class="page-title-wrapper" v-if="meta">
                                    <span class="page-title" v-if="meta.headline">{{meta.headline()}}</span>
                                    <span class="page-description" v-if="meta.description">{{meta.description()}}</span>
                                </div>
                            </li>
                        </ul>

                        <search v-if="isAdmin" />
                    </div>

                    <ul class="nav navbar-nav navbar-right">
                        <template v-if="isClient">
                            <li class="admin-help" v-if="isGhostIdentity">
                                <a @click.prevent="showFAQMenu = !showFAQMenu">
                                    <p>{{$t('faq.dropdownTitle')}}</p>
                                </a>

                                <transition name="slide-fade">
                                    <faq-menu v-if="showFAQMenu" @close="showFAQMenu = false" />
                                </transition>
                            </li>

                            <li class="tour-step-integration">
                                <router-link :to="{name: 'frontend.settings.integrations'}">
                                    <p style="margin-top: 0;">
                                        {{$t('topBar.integrations')}} ({{numberOfActiveIntegrations}}/{{numberOfServices}})
                                    </p>

                                    <progress-bar class="progress"
                                                  @numberOfActiveIntegrations="setNumberOfActiveIntegrations"
                                                  @numberOfServices="setNumberOfServices" />
                                </router-link>
                            </li>

                            <li>
                                <a @click="showProfileMenu = !showProfileMenu" id="profile-menu-link">
                                    <i class="fa fa-caret-down arrow"></i>

                                    <p v-if="customer" style="margin-top: 0;">
										<span style="display: block;">{{ customer.domain }}</span>
									</p>
                                    <p v-else>{{$t('topBar.myAccount')}}</p>
                                </a>

                                <transition name="slide-fade">
                                    <profile-menu v-if="showProfileMenu" @close="showProfileMenu = false" />
                                </transition>
                            </li>
                        </template>

                        <template v-if="isAdmin">
                            <li class="admin-help">
                                <a @click.prevent="showFAQMenu = !showFAQMenu">
                                    <p>{{$t('faq.dropdownTitle')}}</p>
                                </a>

                                <transition name="slide-fade">
                                    <faq-menu v-if="showFAQMenu" @close="showFAQMenu = false" />
                                </transition>
                            </li>

                            <li>
                                <router-link :to="{name: 'backend.profile'}">
                                    <p>{{identity.name}}<br /></p>
                                </router-link>
                            </li>

                            <li>
                                <router-link :to="{name: 'logout'}">
                                    <p>{{$t('topBar.profileMenu.signOut')}}</p>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<style lang="scss" scoped>
    @import "~@/assets/scss/variables/all";

    .top-bar {
        padding: 10px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
        border: 0;
        position: relative;
        min-height: 40px;

        .navbar-collapse {
            display: flex !important;

            .fill {
                flex: 1 1 auto;
                padding-right: 50px;
            }
        }

		.page-title-wrapper {
			line-height: 125%;
		}

        .page-title {
            display: block;
            font-weight: 600;
            font-size: 16px;
        }

        .page-description {
            color: #888;
			font-weight: 500;
            font-size: 12px;
        }

        .navbar-collapse {
            padding: 5px;
        }

        ul.navbar-nav {
            &.navbar-right {
				display: flex;
				align-items: center;
                white-space: nowrap;
                margin: -20px 0;

                li {
                    &.link-faker {
                        div {
                            padding: 10px 18px 0 0;
                            margin-right: 0;
                        }
                    }

                    float: none;
                    display: inline-block;
                    vertical-align: top;

                    a {
                        position: relative;
                        color: #484848;
                        padding-right: 18px;
                        margin-right: 0;
                        font-weight: bold;

                        p {
                            font-weight: 500;

							span.email {
								font-size: 13px;
								font-weight: 400;
							}
                        }

                        .arrow {
                            position: absolute;
                            right: 0;
                            top: 13px;
                            font-size: 12px;
                        }
                    }

                    &.admin-help {
                        a {
                            background: $digital-blue;
                            border-radius: 2px;
                            display: inline-block;
                            padding: 10px 30px;
                            color:#fff;
                            transition: 200ms ease-in-out;
                            &:hover{
                                background:$digital-blue-button-background-hover;
                            }

                            p {
                                padding: 0;
                                font-weight:600;
                            }
                        }
                    }

                    &:last-child {
                        a {
                            margin-right: 0;
                        }
                    }

                    .progress {
                        position: absolute;
                        width: 65%;
                        top: 39px;
                        left: 26px;
                        height: 2px;
                    }
                }
            }
        }

        .navbar-form {
            margin: 0;
        }

        .notification {
            &.pulse {
                margin:150px;
                display: block;
                left: -150px;
                top: -150px;
                border-radius: 50%;
                cursor: pointer;
                box-shadow: 0 0 0 rgba(255, 2, 40, 0.4);
                animation: pulse 2s infinite;
            }

            &.pulse:hover {
                animation: none;
            }
        }
    }

    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(249, 151, 11, 0.4);
            box-shadow: 0 0 0 0 rgba(249, 151, 11, 0.4);
        }
        30% {
            -moz-box-shadow: 0 0 0 12px rgba(249, 151, 11, 0);
            box-shadow: 0 0 0 12px rgba(249, 151, 11, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 12px rgba(249, 151, 11, 0);
            box-shadow: 0 0 0 12px rgba(249, 151, 11, 0);
        }
    }

    .slide-fade-enter-active {
        transition: all .4s ease;
    }
    .slide-fade-leave-active {
        transition: all .4s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }

    @media screen and (max-width: 1200px) {
        .top-bar {
            ul.navbar-nav {
                li {
                    &.link-faker {
                        display: none;
                    }
                }
            }
        }
    }
</style>

<script>
    import Search from '@/app/shared/global/template/Search'
    import ProfileMenu from '@/app/shared/global/template/ProfileMenu'
    import faqMenu from '@/app/faq/components/FAQMenu'
    import ProgressBar from '@/app/integrations/components/ActiveIntegrationsProgressBar'

    import { FAQContent } from '@/app/faq/components/FAQContent'

    import {mapGetters} from 'vuex'

    export default {
        data() {
            return {
                showProfileMenu: false,
                showFAQMenu: false,
                meta: {},
                numberOfActiveIntegrations: 0,
                numberOfServices: 0,
                path: ''
            }
        },

        watch: {
            '$route'() {
                this.setRouteMeta()
                this.setRoutePath()
            }
        },

        mounted() {
            this.setRouteMeta()
            this.setRoutePath()
        },

        computed: {
          ...mapGetters('identity', {
            isClient: 'isClient',
            isAdmin: 'isAdmin',
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),
          ...mapGetters('ghost', ['isGhostIdentity']),
        },

        methods: {
            setRouteMeta() {
                if (!this.$router.currentRoute) {
                    this.meta = {}
                }

                this.meta = this.$router.currentRoute.meta
            },

            setRoutePath() {
                if (!this.$route.path) {
                    this.path = ''
                }

                this.path = this.$route.path
            },

            setNumberOfActiveIntegrations(count) {
                this.numberOfActiveIntegrations = count
            },

            setNumberOfServices(count) {
                this.numberOfServices = count
            },
        },

        components: {
            Search,
            ProfileMenu,
            ProgressBar,
            faqMenu
        }
    }
</script>
