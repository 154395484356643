<template>
    <div class="top-bar-faq-menu"
         v-on-clickaway="close"
         :style="dynamicHeight">
        <ul>
            <li class="search-query">
                <i class="pe-7s-search"/>
                <input ref="query"
                       type="text"
                       :placeholder="$t('faq.placeholderSearch')"
                       v-model="query">
                <i class="pe-7s-close"
                   :class="{ active: query }"
                   @click="resetQuery"/>
            </li>

            <li class="divider"/>

            <template v-for="subject in sortedFAQSubjects">
                <li>
                    <a href="#" @click.prevent="selected = subject.id">
                        {{subject.headline}}
                        <i class="pe-7s-angle-right"/>
                    </a>
                </li>
                <li class="divider"/>
            </template>

            <li class="no-result" v-if="!sortedFAQSubjects.length">
                <a href="#">
                    {{$t('faq.noSearchResult')}}
                </a>
            </li>

            <li>
                <a href="#"
                   class="faq-dropdown-footer"
                   @click.prevent="goToFAQPage"
                   :style="[selected ? fixBottom : null, organizationBackground]">
                    {{$t('faq.footer')}}
                </a>
            </li>
        </ul>

        <faq-menu-subject v-for="(subject, i) in sortedFAQSubjects"
                          @back="resetSelected"
                          :key="i"
                          :class="{ activeSubject: selected === subject.id }"
                          :subject="subject"/>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    a {
        display: inline-block;
        position: relative;
        @include ellipsis;

        .pe-7s-angle-right {
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            transition: transform 0.4s ease;
            font-size: 22px;
        }

        &:hover {
            .pe-7s-angle-right {
                transform: translate(3px, -50%);
            }
        }
    }

    .no-result {
        background: #9b5353;
        font-style: italic;

        a {
            color: #ffffff !important;
        }
    }

    .top-bar-faq-menu {
        overflow: hidden;
        background-color: #ffffff;
        position: absolute;
        top: 78px;
        right: -8px;
        border: 1px solid #dedede;
        width: 400px;
        border-radius: 4px;
        z-index: 999999;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25), 0 5px 15px rgba(0, 0, 0, 0.05);
        transition: all 0.5s ease;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                &:not(.no-result, a.faq-dropdown-footer):hover {
                    a {
                        background: #f5f5f5;
                    }
                }

                &.search-query {
                    display: block;
                    font-size: 14px;
                    height: 48px;

                    input {
                        display: inline-block;
                        width: 70%;
                        height: 48px;
                        border: none;
                        padding: 0;
                        margin: 0;
                        position: relative;
                    }

                    i {
                        display: inline-block;
                        font-size: 24px;
                        width: calc(15% - 3px);
                        height: 10px;
                        margin: 0;
                        padding: 0;
                        position: relative;

                        &:before {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }

                        &.pe-7s-close {
                            transition: opacity 0.5s ease;
                            cursor: pointer;
                            opacity: 0;

                            &.active {
                                opacity: 1;
                            }
                        }
                    }
                }

                a {
                    color: #000000;
                    display: block;
                    line-height: 46px;
                    padding: 0 50px 0 30px;
                    font-size: 14px;

                    &.faq-dropdown-footer {
                        color: #ffffff;
                        background: #12374c;
                        border-radius: 0 0 3px 3px;
                    }
                }

                &.divider {
                    border-bottom: 1px solid #dedede;
                }
            }
        }
    }
</style>

<script>
  import faqMenuSubject from '@/app/faq/components/FAQMenuSubject'
  import { mixin as clickaway } from 'vue-clickaway'
  import { FAQContent } from '@/app/faq/components/FAQContent'

  import {cloneDeep} from 'lodash'
  import Fuse from 'fuse.js'

  export default {
    mixins: [clickaway],

    data () {
      return {
        faqContent: null,
        query: '',
        selected: '',
        maxNumberOfSubjects: 3,
        footerHeight: 48,
        timeout: null,
        searchOptions: {
          shouldSort: true,
          threshold: 0.4,
          location: 0,
          distance: 100,
          maxPatternLength: 16,
          minMatchCharLength: 1,
          keys: [
            'headline',
            'tags'
          ]
        }
      }
    },

    watch: {
      // Search Query Debounce
      query () {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
          this.loadFAQContent()
        }, 500)
      },

      '$route' () {
        this.close()
      }
    },

    computed: {
      content () {
        return this.prepareContent(cloneDeep(FAQContent))
      },

      organizationBackground () {
        return { background: $org('colors.standard.primary.hex') }
      },

      fixBottom () {
        if (!this.dynamicHeight) {
          return
        }

        return {
          'position': 'absolute',
          'left': 0,
          'bottom': 0,
          'width': '100%'
        }
      },

      sortedFAQSubjects () {
        if (!this.faqContent) {
          return []
        }

        return Object.values(this.faqContent)
          .sort(a => a.pageReferences.includes(this.$route.name) ? -1 : 1)
          .sort((a, b) => {
            const aIncludesPagesReference = a.pageReferences.includes(this.$route.name)
            const bIncludesPagesReference = b.pageReferences.includes(this.$route.name)

            if (aIncludesPagesReference && !bIncludesPagesReference ||
              !aIncludesPagesReference && bIncludesPagesReference
            ) {
              return 0
            }

            if (aIncludesPagesReference &&
              bIncludesPagesReference
            ) {
              return a.pagePriority < b.pagePriority ? -1 : 1
            }

            return a.overallPriority < b.overallPriority ? -1 : 1
          })
          .filter((n, index) => index <= this.maxNumberOfSubjects - 1)
      },

      dynamicHeight () {
        if (this.selected === '') {
          return null
        }

        const selectedSubjectHeight = document.getElementById(`faq-menu-subject-${this.selected}`).offsetHeight

        return {
          height: `${selectedSubjectHeight + this.footerHeight}px`
        }
      }
    },

    mounted () {
      this.faqContent = this.content

      this.$nextTick(() => this.$refs.query.focus())
    },

    methods: {
      close () {
        this.faqContent = null
        this.$emit('close')
      },

      resetSelected () {
        this.selected = ''
      },

      resetQuery () {
        this.query = ''
      },

      prepareContent (content) {
        if (content instanceof Function) {
          return content()
        }

        if (content instanceof Object) {
          for (let key in content) {
            content[key] = this.prepareContent(content[key])
          }
        }

        return content
      },

      loadFAQContent () {
        if (!this.query.length) {
          this.faqContent = this.content
          return
        }

        const fuse = new Fuse(Object.values(this.content), this.searchOptions)
        this.faqContent = fuse.search(this.query)
      },

      goToFAQPage () {
        const routeName = this.$route.name.includes('frontend')
          ? 'frontend.faq'
          : 'backend.faq'

        this.$router.push({ name: routeName })
      }
    },

    components: {
      faqMenuSubject
    }
  }
</script>
