<template>
    <div class="top-bar-profile-menu" v-on-clickaway="close">
        <span class="arrow"></span>

        <ul>
            <li>
                <a @click="toPage('account')">{{$t('topBar.profileMenu.settings')}}</a>
            </li>

            <li>
                <router-link :to="{name: 'billing'}">{{$t('topBar.profileMenu.subscriptions')}}</router-link>
            </li>

			<li v-if="customers.length > 1"
				@click="showSwitchCustomer">
				<button>{{ $t('topBar.profileMenu.changeCustomer') }}</button>
			</li>

            <li class="divider"></li>

            <li>
                <router-link :to="{name: isGhostIdentity ? 'ghost-logout' : 'logout'}">
                    {{$t('topBar.profileMenu.signOut')}}
                </router-link>
            </li>
        </ul>

		<modal ref="switch-customer"
			   :title="$t('topBar.profileMenu.changeCustomer')">
			<div class="customers">
				<div v-for="c in customers"
					 @click="switchCustomer(c)"
					 :key="`customer-${c.id}`"
					 class="customer">
					<strong>
						{{ c.domain }}
					</strong>

					<i v-if="customer && c.id === customer.id"
					   class="pe-7s-check"
					/>
				</div>
			</div>
		</modal>
    </div>
</template>

<script>
  import { mixin as clickaway } from 'vue-clickaway'
  import {mapGetters} from 'vuex'

  import Modal from '../../../layout/components/Modal'

  import StorageService from "@/services/_app/storage/StorageService";
  import {STORAGE_CUSTOMER_KEY} from "@/app/users/customer.store";

  export default {
    mixins: [clickaway],

    computed: {
	  ...mapGetters('ghost', ['isGhostIdentity']),
	  ...mapGetters('customer', {
		  customer: 'getCustomer',
		  customers: 'getCustomers',
	  }),
  	},

    methods: {
      close () {
        this.$emit('close')
      },

      toPage (pageName) {
        this.close()

        this.$router.push({
          name: 'frontend.settings.profile.page', params: {
            page: pageName
          }
        })
      },

	  showSwitchCustomer() {
		jQuery(this.$refs['switch-customer'].$el).modal('show')
	  },

	  switchCustomer(customer) {
		StorageService.set(STORAGE_CUSTOMER_KEY, customer.id)
		window.location.reload()
	  }
    },

	components: {
	  Modal
	}
  }
</script>

<style lang="scss" scoped>
.top-bar-profile-menu {
	background-color: white;
	width: 200px;
	position: absolute;
	top: 78px;
	right: -8px;
	border: 1px solid #dedede;
	border-radius: 4px;
	z-index: 999999;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25), 0 5px 15px rgba(0, 0, 0, 0.05);

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			a,
			button {
				color: #888;
				display: block;
				line-height: 46px;
				padding-left: 30px;
				font-size: 14px;
				outline: 0;
				border: 0;
				width: 100%;
				text-align: left;
				background-color: transparent;
				box-shadow: none;

				&:hover {
					background: #f5f5f5;
				}
			}

			&.divider {
				border-bottom: 1px solid #dedede;
			}
		}
	}

	.arrow {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;

		position: absolute;
		top: -6px;
		right: 53px;
		margin-left: -4px;
	}
}

.customers {
	display: flex;
	flex-direction: column;
	justify-content: center;

	> * {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 50px;
		padding: 10px 20px;
		border-bottom: 1px solid #eee;

		strong {
			font-size: 16px;
			font-weight: 500;
		}

		i {
			font-size: 28px;
		}

		&:last-child {
			border-bottom: 0;
		}

		&:hover {
			cursor: pointer;
			background-color: #eee;
		}
	}
}
</style>
