export const FAQContent = [
    {
        id: 1,
        headline: (() => $t('faq.dropdown.competitorTraffic.headline')),
        content: (() => $t('faq.dropdown.competitorTraffic.content')),
        shortContent: (() => $t('faq.dropdown.competitorTraffic.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'competitor-traffic' }},
        overallPriority: 4,
        pagePriority: 5,
        pageReferences: ['frontend.reporting.competitors', 'frontend.overview'],
        tags: [
            (() => $t('faq.dropdown.searchTags.googleAnalytics')),
            (() => $t('faq.dropdown.searchTags.traffic')),
            (() => $t('faq.dropdown.searchTags.competitor'))
        ],
        relation: 'competitor',
        faqPageRelated: {
            dataColumn: 'SEMRush',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 2,
        headline: (() => $t('faq.dropdown.userCreation.headline')),
        content: (() => $t('faq.dropdown.userCreation.content')),
        shortContent: (() => $t('faq.dropdown.userCreation.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'user-creation' }},
        overallPriority: 7,
        pagePriority: 1,
        pageReferences: ['backend.customers.view.users', 'frontend.settings.profile.page'],
        tags: [
            (() => $t('faq.dropdown.searchTags.createUser')),
            (() => $t('faq.dropdown.searchTags.client')),
            (() => $t('faq.dropdown.searchTags.overview')),
            (() => $t('faq.dropdown.searchTags.email'))
        ],
        relation: 'user',
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 3,
        headline: (() => $t('faq.dropdown.userLanguageChange.headline')),
        content: (() => $t('faq.dropdown.userLanguageChange.content')),
        shortContent: (() => $t('faq.dropdown.userLanguageChange.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'user-language-change' }},
        overallPriority: 10,
        pagePriority: 9,
        pageReferences: ['frontend.settings.profile.page', 'backend.customers.view.users'],
        tags: [
            (() => $t('faq.dropdown.searchTags.client')),
            (() => $t('faq.dropdown.searchTags.language'))
        ],
        relation: 'user',
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 4,
        headline: (() => $t('faq.dropdown.feedDates.headline')),
        content: (() => $t('faq.dropdown.feedDates.content')),
        shortContent: (() => $t('faq.dropdown.feedDates.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'feed-dates' }},
        overallPriority: 3,
        pagePriority: 5,
        pageReferences: ['frontend.overview'],
        tags: [
            (() => $t('faq.dropdown.searchTags.feed')),
            (() => $t('faq.dropdown.searchTags.news')),
            (() => $t('faq.dropdown.searchTags.ssl'))
        ],
        relation: 'feed',
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 5,
        headline: (() => $t('faq.dropdown.integrationAnalyticsFilter.headline')),
        content: (() => $t('faq.dropdown.integrationAnalyticsFilter.content')),
        shortContent: (() => $t('faq.dropdown.integrationAnalyticsFilter.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'integration-analytics-filter' }},
        overallPriority: 2,
        pagePriority: 2,
        pageReferences: ['frontend.reporting.visitors'],
        tags: [
            (() => $t('faq.dropdown.searchTags.googleAnalytics')),
            (() => $t('faq.dropdown.searchTags.excludeSpam')),
            (() => $t('faq.dropdown.searchTags.website')),
            (() => $t('faq.dropdown.searchTags.integration'))
        ],
        relation: 'integration',
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: [
                'faq-analytics-01-integration.png',
                'faq-analytics-01-linechart.png'
            ]
        }
    },
    {
        id: 6,
        headline: (() => $t('faq.dropdown.ghostIdentityIntegration.headline')),
        content: (() => $t('faq.dropdown.ghostIdentityIntegration.content')),
        shortContent: (() => $t('faq.dropdown.ghostIdentityIntegration.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'ghost-identity-integration' }},
        overallPriority: 9,
        pagePriority: 1,
        pageReferences: ['frontend.settings.integrations', 'frontend.overview'],
        tags: [
            (() => $t('faq.dropdown.searchTags.ghostIdentity')),
            (() => $t('faq.dropdown.searchTags.integration'))
        ],
        relation: 'integration',
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: [
                'faq-integrate-ghost-opn.png'
            ]
        }
    },
    {
        id: 7,
        headline: (() => $t('faq.dropdown.guideGoogleSearchConsole.headline')),
        content: (() => $t('faq.dropdown.guideGoogleSearchConsole.content')),
        shortContent: (() => $t('faq.dropdown.guideGoogleSearchConsole.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'guide-google-search-console' }},
        overallPriority: 10,
        pagePriority: 10,
        pageReferences: [],
        tags: [
            (() => $t('faq.dropdown.searchTags.guide'))
        ],
        relation: 'guide',
        guideContent: [
            {
                headline: '1. Del adgangen med en MCC account',
                content: 'Først og fremmest skal du dele adgangen med den pågældende Google Search Console konto med en af vores MCC accounts.',
                ol: [
                    {li: 'Log ind på search.google.com'},
                    {li: 'Vælg det korrekte domæne fra drop down til venstre'},
                    {li: 'Vælg settings i bund venstre side'},
                    {li: 'Under settings vælg Brugere og Tilladelse'}
                ],
                aText: 'Se Google Search Console Guide',
                aURL: 'https://www.monsterinsights.com/docs/add-user-google-search-console/'
            },
            {
                headline: "2. Tilføj Google Analytics integrationen i OP'N",
                content: '',
                ol: [
                    {li: "Åben OP'N (https://app.opnx.dk)"},
                    {li: 'Find kunden i søgefeltet øverst'},
                    {li: 'Klik på fanen “Integrationer”'},
                    {li: 'Find integrationen “Google Search Console”'}
                ],
                aText: '',
                aURL: ''
            }
        ],
        guideFAQ: [
            {
                headline: "1. Hvorfor kan jeg ikke finde kontoen i OP'N?",
                content: 'Der kan gå op til 24 timer fra en search console konto deles med en MCC account til den fremgår i OP\'N. Dobbelttjek også, at den pågældende MCC account er integreret med OP\'N. Det gør du ved at tjekke, at kontoen kan findes i listen “MCC konti” i administrationen i OP\'N.'
            },
            {
                headline: '2. Hvorfor kan jeg kun se 50 søgeord i OP\'N?',
                content: 'Vi begrænser mængden af søgeord der hentes fra Google Search Console til 50, men de er de søgeord der performer bedst af dem der findes. Dette skyldes mængden af kald der potentielt skulle laves til Google hvis kunden havde f.eks. 8.000 søgeord.'
            },
            {
                headline: '3. Jeg får en fejl ved opkobling?',
                content: 'Hvis du ved opkobling får en fejl, så fjern integrationen og prøv igen.'
            }
        ],
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 8,
        headline: (() => $t('faq.dropdown.guideGoogleAnalytics.headline')),
        content: (() => $t('faq.dropdown.guideGoogleAnalytics.content')),
        shortContent: (() => $t('faq.dropdown.guideGoogleAnalytics.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'guide-google-analytics' }},
        overallPriority: 10,
        pagePriority: 10,
        pageReferences: [],
        tags: [
            (() => $t('faq.dropdown.searchTags.guide'))
        ],
        relation: 'guide',
        guideContent: [
            {
                headline: '1. Del adgangen med en MCC account',
                content: 'Først og fremmest skal du dele adgangen med den pågældende Google Analytics konto med en af vores MCC accounts. (f.eks. ga@sgme.dk)',
                ol: [
                    {li: 'Klik på Administrator'},
                    {li: 'Klik på brugeradministration i kolonnen Konto, Ejendom eller Visning.'},
                    {li: 'Tildel den korrekte tilladelse til en MCC Konto'}
                ],
                aText: 'Se Google Analytics Guide',
                aURL: 'https://support.google.com/analytics/answer/2884495?hl=da'
            },
            {
                headline: '2. Tilføj Google Analytics integrationen i OP\'N',
                content: '',
                ol: [
                    {li: 'Åben OP\'N (https://app.opnx.dk)'},
                    {li: 'Find kunden i søgefeltet øverst'},
                    {li: 'Klik på fanen “Integrationer”'},
                    {li: 'Find integrationen “Google Analytics”'},
                    {li: 'Find annoncekontien i listen ved at søge på navnet.'},
                ],
                aText: '',
                aURL: ''
            }
        ],
        guideFAQ: [
            {
                headline: '1. Hvorfor kan jeg ikke finde kontoen i OP\'N?',
                content: 'Der kan gå op til 24 timer fra en analytics konto deles med en MCC account til den fremgår i OP\'N. Dobbelttjek også, at den pågældende MCC account er integreret med OP\'N. Det gør du ved at tjekke, at kontoen kan findes i listen “MCC konti” i administrationen i OP\'N.'
            },
            {
                headline: '2. Kundens besøgstal stemmer ikke overens med tal fra Google Analytics',
                content: 'Hvis du oplever at tallene ikke passer i OP\'N, kan du prøve følgende ting.',
                ol: [
                    {li: 'En mulighed er at integrationen er sat op med en forkert visning. Tidligere OP\'N Marketing kunder, har f.eks. på hver konto en “Standard” og en “Eksluder Spam” visning.'},
                    {li: 'Dobbelttjek at du holder de samme tal op imod hinanden, OP\'N hiver besøgstal fra Google Analytics > Anskaffelse > Al Trafik > Kanaler, og kig her efter Sessioner'},
                    {li: 'En sidste mulighed er at kunden har udenlandsk trafik slået fra i O\'N, dette kan du ændre ved at gå til kundes settings side, øverst til højre, og her klik Inkluder udenlandsk trafik til.'}
                ],
                subContent: 'Det sker at besøg bliver forvekslet med “Sidevisninger”, og disse kunne en enkelt bruger i princippet stå for 40 af, hvilket kan give nogle enormt høje tal, vær opmærksom på dette.'
            },
            {
                headline: '3. Hvorfor dykker besøgstal i slutningen af grafen?',
                content: 'Grafen viser antallet af besøg for den valgte periode, om end du har valgt daglig / ugentlig eller anden visning, den sidste del der fremstår med stiplet linie, er en uafsluttet periode.'
            },
            {
                headline: '4. Jeg får en fejl ved opkobling?',
                content: 'Hvis du ved opkobling får en fejl, så fjern integrationen og prøv igen.'
            }
        ],
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 9,
        headline: (() => $t('faq.dropdown.guideGoogleAds.headline')),
        content: (() => $t('faq.dropdown.guideGoogleAds.content')),
        shortContent: (() => $t('faq.dropdown.guideGoogleAds.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'guide-google-ads' }},
        overallPriority: 10,
        pagePriority: 10,
        pageReferences: [],
        tags: [
            (() => $t('faq.dropdown.searchTags.guide'))
        ],
        relation: 'guide',
        guideContent: [
            {
                headline: '1. Del adgangen med en MCC account',
                content: 'Først og fremmest skal du dele adgangen med den pågældende Google Ads konto med en af vores MCC accounts.',
                ol: [
                    {li: 'Log ind på kundens Google Ads konto (eller få kunden til følgende)'},
                    {li: 'Klik på Indstillinger og vælg Kontoindstillinger'},
                    {li: 'Vælg Konto Adgang fra navigationen'},
                    {li: 'Klik +Brugere'},
                    {li: 'Indtast den ønskede MCC account'},
                    {li: 'Udfyld de nødvendige felter såsom Brugernavn og Rettigheder'}
                ],
                aText: 'Se Google Ads Guide',
                aURL: 'https://support.google.com/google-ads/answer/6372672?co=ADWORDS.IsAWNCustomer%3Dfalse&hl=en'
            },
            {
                headline: '2. Tilføj Google Ads integrationen i OP\'N',
                content: '',
                ol: [
                    {li: 'Åben OP\'n (https://app.opnx.dk)'},
                    {li: 'Find kunden i søgefeltet øverst'},
                    {li: 'Klik på fanen “Integrationer”'},
                    {li: 'Find integrationen “Google Ads”'},
                    {li: 'Find annoncekontien i listen ved at søge på navnet.'}
                ],
                aText: '',
                aURL: ''
            }
        ],
        guideFAQ: [
            {
                headline: '1. Hvorfor kan jeg ikke finde kontoen i OP\'N?',
                content: 'Der kan gå op til 24 timer fra en google ads konto deles med en MCC account til den fremgår i OP\'N. Dobbelttjek også, at den pågældende MCC account er integreret med OP\'N. Det gør du ved at tjekke, at kontoen kan findes i listen “MCC konti” i administrationen i OP\'N.'
            },
            {
                headline: '2. Jeg får en fejl ved opkobling?',
                content: 'Hvis du ved opkobling får en fejl, så fjern integrationen og prøv igen.'
            }
        ],
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 10,
        headline: (() => $t('faq.dropdown.guideGoogleMyBusiness.headline')),
        content: (() => $t('faq.dropdown.guideGoogleMyBusiness.content')),
        shortContent: (() => $t('faq.dropdown.guideGoogleMyBusiness.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'guide-google-my-business' }},
        overallPriority: 10,
        pagePriority: 10,
        pageReferences: [],
        tags: [
            (() => $t('faq.dropdown.searchTags.guide'))
        ],
        relation: 'guide',
        guideContent: [
            {
                headline: '1. Del adgangen med en MCC account',
                content: 'Først og fremmest skal du dele adgangen med den pågældende Google MyBusiness konto med en af vores MCC accounts.',
                ol: [
                    {li: 'Log ind på Google MyBusiness'},
                    {li: 'Åbn den placering, du vil administrere (Hvis du har flere)'},
                    {li: 'Klik på Brugere på menuen'},
                    {li: 'Klik på ikonet Inviter nye brugere øverst til højre'},
                    {li: 'Indtast e-mail på MCC kontoen du ønsker at forbinde med (Muligvis bureau ID)'},
                    {li: 'Vælg bruger rolle'},
                    {li: 'Klik på inviter'}
                ],
                aText: 'Se Google MyBusiness Guide',
                aURL: 'https://support.google.com/business/answer/3403100?hl=da'
            },
            {
                headline: '2. Tilføj Google Analytics integrationen i OP\'N',
                content: '',
                ol: [
                    {li: 'Åben OP\'N (https://app.opnx.dk)'},
                    {li: 'Find kunden i søgefeltet øverst'},
                    {li: 'Klik på fanen “Integrationer”'},
                    {li: 'Find integrationen “Google MyBusiness”'},
                    {li: 'Find annoncekontien i listen ved at søge på navnet.'}
                ],
                aText: '',
                aURL: ''
            }
        ],
        guideFAQ: [
            {
                headline: '1. Hvorfor kan jeg ikke finde kontoen i OP\'N?',
                content: 'Der kan gå op til 24 timer fra en google my business konto deles med en MCC account til den fremgår i OP\'N. Dobbelttjek også, at den pågældende MCC account er integreret med OP\'N. Det gør du ved at tjekke, at kontoen kan findes i listen “MCC konti” i administrationen i OP\'N.'
            },
            {
                headline: '2. Jeg får en fejl ved opkobling?',
                content: 'Hvis du ved opkobling får en fejl, så fjern integrationen og prøv igen.'
            }
        ],
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 11,
        headline: (() => $t('faq.dropdown.guideFacebookAds.headline')),
        content: (() => $t('faq.dropdown.guideFacebookAds.content')),
        shortContent: (() => $t('faq.dropdown.guideFacebookAds.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'guide-facebook-ads' }},
        overallPriority: 10,
        pagePriority: 1,
        pageReferences: ['frontend.reporting.facebook.ads'],
        tags: [
            (() => $t('faq.dropdown.searchTags.guide'))
        ],
        relation: 'guide',
        guideContent: [
            {
                headline: '1. Del adgangen med en MCC account',
                content: 'Først og fremmest skal du dele adgangen med den pågældende Facebook Business Manager med en af vores MCC accounts.',
                ol: [
                    {li: 'Bed kunden give den korrekte MCC konto adgang ELLER Anmod om adgang'},
                    {li: 'Ved anmodning: Gå til business.facebook.com'},
                    {li: 'Virksomheds indstillinger'},
                    {li: 'Klik på Sider i menuen'},
                    {li: 'Tilføj'},
                    {li: 'Anmod om adgang'},
                    {li: 'HUSK: At bede om administratoradgang'}
                ],
                aText: 'Se Facebook Business Manager Guide',
                aURL: 'https://www.facebook.com/business/help/183277585892925'
            },
            {
                headline: '2. Tilføj Facebook Business Manager integrationen i OP\'N',
                content: '',
                ol: [
                    {li: 'Åben OP\'N (https://app.opnx.dk)'},
                    {li: 'Find kunden i søgefeltet øverst'},
                    {li: 'Klik på fanen “Integrationer”'},
                    {li: 'Find integrationen “Facebook Business Manager”'},
                    {li: 'Find annoncekontien i listen ved at søge på navnet.'}
                ],
                aText: '',
                aURL: ''
            }
        ],
        guideFAQ: [
            {
                headline: '1. Hvorfor kan jeg ikke finde kontoen i OP\'N?',
                content: 'Der kan gå op til 24 timer fra en annoncekonto deles med en MCC account til den fremgår i OP\'N. Dobbelttjek også, at den pågældende MCC account er integreret med OP\'N. Det gør du ved at tjekke, at kontoen kan findes i listen “MCC konti” i administrationen i OP\'N.'
            },
            {
                headline: '2. Jeg kan se overordnet annonce statistik, men ikke se de individuelle annoncer?',
                content: 'Det tyder på, at du ikke har fulgt punkt 2 fra “Guide til opsætning”. Hvis ikke du tilføjer Nikolaj som annonceadministrator, så er det ikke muligt at se de individuelle annoncer.'
            },
            {
                headline: '3. Jeg får en fejl ved opkobling?',
                content: 'Hvis du ved opkobling får en fejl, så fjern integrationen og prøv igen.'
            },
            {
                headline: '4. Jeg kan kun se overordnet data?',
                content: 'Hvis du efter integration af Facebook Ads kun kan se overordnet kampagne statistik, så mangler du at tilføje "Louise Søgesen" i Facebook\s dashboard.'
            }
        ],
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 12,
        headline: (() => $t('faq.dropdown.guideFacebookPages.headline')),
        content: (() => $t('faq.dropdown.guideFacebookPages.content')),
        shortContent: (() => $t('faq.dropdown.guideFacebookPages.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'guide-facebook-pages' }},
        overallPriority: 10,
        pagePriority: 10,
        pageReferences: [],
        tags: [
            (() => $t('faq.dropdown.searchTags.guide'))
        ],
        relation: 'guide',
        guideContent: [
            {
                headline: '1. Del adgangen med en MCC account',
                content: 'Først og fremmest skal du dele adgangen med den pågældende Facebook Pages med en af vores MCC accounts.',
                ol: [
                    {li: 'Bed kunden give den korrekte MCC konto adgang ELLER Anmod om adgang'},
                    {li: 'Ved anmodning: Gå til business.facebook.com'},
                    {li: 'Virksomheds indstillinger'},
                    {li: 'Klik på Sider i menuen'},
                    {li: 'Tilføj'},
                    {li: 'Anmod om adgang'},
                    {li: 'HUSK: At bede om administratoradgang'}
                ],
                aText: '',
                aURL: ''
            },
            {
                headline: '2. Tilføj Facebook Pages integrationen i OP\'N',
                content: '',
                ol: [
                    {li: 'Åben OP\'N (https://app.opnx.dk)'},
                    {li: 'Find kunden i søgefeltet øverst'},
                    {li: 'Klik på fanen “Integrationer”'},
                    {li: 'Find integrationen “Facebook Pages"'},
                    {li: 'Find annoncekontien i listen ved at søge på navnet.'}
                ],
                aText: '',
                aURL: ''
            }
        ],
        guideFAQ: [
            {
                headline: '1. Hvorfor kan jeg ikke finde kontoen i OP\'N?',
                content: 'Der kan gå op til 24 timer fra en annoncekonto deles med en MCC account til den fremgår i OP\'N. Dobbelttjek også, at den pågældende MCC account er integreret med OP\'N. Det gør du ved at tjekke, at kontoen kan findes i listen “MCC konti” i administrationen i OP\'N.'
            },
            {
                headline: '2. Jeg får en fejl ved opkobling?',
                content: 'Hvis du ved opkobling får en fejl, så fjern integrationen og prøv igen.'
            }
        ],
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    },
    {
        id: 13,
        headline: (() => $t('faq.dropdown.guideSEO.headline')),
        content: (() => $t('faq.dropdown.guideSEO.content')),
        shortContent: (() => $t('faq.dropdown.guideSEO.shortContent')),
        route: {name: 'frontend.settings.integrations.trustpilot.setup', query: { slug: 'guide-seo' }},
        overallPriority: 10,
        pagePriority: 10,
        pageReferences: [],
        tags: [
            (() => $t('faq.dropdown.searchTags.guide'))
        ],
        relation: 'guide',
        guideContent: [
            {
                headline: 'SEO Opsætning',
                content: 'SEO er ikke en normal integration, at tilføje søgeord i OP\'N skaber automatisk en “integration”, så når der tales om SEO integration, tales der om hvorvidt kunden/brugeren har aktive søgeord i OP\'N.'
            },
            {
                headline: 'Guide til opsætning',
                content: '',
                ol: [
                    {li: 'Find kunden i OP\'N ved at søge efter deres navn på kundelisten'},
                    {li: 'Til højre vælg fanebladet “Søgeord” og begynd at tilføje'}
                ],
                aText: '',
                aURL: ''
            }
        ],
        guideFAQ: [
            {
                headline: '',
                content: '',
                ol: [
                    {li: 'Søgeordenes placering på kunden er ikke korrekte?'},
                    {li: 'Hvor ofte bliver der hentet placeringer?'}
                ]
            }
        ],
        faqPageRelated: {
            dataColumn: '',
            relatedColumn: '',
            images: []
        }
    }
]

/*

    BLUEPRINT

    {
        guideContent: Array
            Object - LOOP
                id: Number
                headline: Function (IIFE)
                content: Function (IIFE)
                shortContent: Function (IIFE)
                route: Object
                overallPriority: Number
                pagePriority: Number
                pageReferences: Array
                tags: Array
                relation: String
                guideContent: Array
                    Object - LOOP
                        headline: String
                        content: String
                        ol: Array
                            Object
                                li: String
                aText: String
                aURL: String

        guideFAQ: Array
            Object - LOOP
                headline: String
                content: String
                ol: Array
                    Object - LOOP
                        li: String

*/