<template>
    <div class="faq-menu-subject" :id="`faq-menu-subject-${subject.id}`">
        <h4>{{subject.headline}}</h4>
        <p class="content" v-html="subject.content" />
        <p class="short-content" v-if="subject.shortContent">
            <span class="prologue">
                {{$t('faq.shortContentPrologue')}}
            </span>
            {{subject.shortContent}}
        </p>
        <button class="btn btn-sm" :style="btnInverse" @click="back">{{$t('faq.back')}}</button>
        <button class="btn btn-sm" :style="btnFilled" @click="goToFAQPage">{{$t('faq.seeMore')}}</button>
    </div>
</template>

<style lang="scss" scoped>
    .btn {
        margin: 20px 0;
        opacity: 1;
        transition: opacity 0.5s ease;
        cursor: default;
        &:hover {
            opacity: 0.9;
        }
    }

    h4 {
        font-weight: 500;
    }

    .prologue {
        font-weight: 700;
        font-style: italic;
    }

    .content {
        margin-bottom: 20px;
    }

    .short-content {
        background: #f8f8f8;
        display: inline-block;
        border-radius: 3px;
        padding: 10px 30px 10px 30px !important;
        margin: 20px 0 20px -30px;
        width: calc(100% + 80px);
    }

    .faq-menu-subject {
        white-space: normal;
        width: 100%;
        min-height: calc(100% - 46px);
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(100%);
        transition: all 0.5s ease;
        background: #ffffff;
        opacity: 0;
        padding: 0 50px 30px 30px;

        p:not(.short-content) {
            padding: 0;
        }
    }

    .activeSubject {
        transform: translateX(0%);
        opacity: 1;
    }
</style>

<script>
    export default {
        props: {
            subject: {
                type: Object,
                required: true
            }
        },

        computed: {
            btnFilled() {
                return {
                    backgroundColor: $org('colors.standard.primary.hex'),
                    color: '#ffffff',
                    border: `1px solid ${$org('colors.standard.primary.hex')}`,
                    opacity: '1'
                }
            },

            btnInverse() {
                return {
                    background: '#ffffff',
                    color: $org('colors.standard.primary.hex'),
                    border: `1px solid ${$org('colors.standard.primary.hex')}`
                }
            }
        },

        methods: {
            back() {
                this.$emit('back')
            },

            goToFAQPage() {
                const id = this.subject.id
                const routeName = this.$route.name.includes('frontend')
                    ? 'frontend.faq'
                    : 'backend.faq'

                this.$router.push({ name: routeName, query: { id }})
            }
        }
    }
</script>